import { useState } from 'react';
import { Form, Button, Alert, ButtonGroup, Badge, Nav, Navbar, Container } from "react-bootstrap";
import Style from './index.module.scss';
const IcebergStylesTest = () => {
  const [testInput, setTestInput] = useState<string>('');
  return (
    <div style={{ width: '50%', margin: '0 auto', padding: '2em' }}>
      <div style={{ marginTop: '1em' }}>
        <h5>Input</h5>
        <Form.Group>
          <Form.Label>Test</Form.Label>
          <Form.Control value={testInput} onChange={(e) => setTestInput(e.target.value)}></Form.Control>
          <Form.Text>Testing form input</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Invalid Test</Form.Label>
          <Form.Control isInvalid value={testInput} onChange={(e) => setTestInput(e.target.value)}></Form.Control>
          <Form.Control.Feedback>Invalid form input</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Valid Test</Form.Label>
          <Form.Control isValid value={testInput} onChange={(e) => setTestInput(e.target.value)}></Form.Control>
          <Form.Control.Feedback>Valid form input</Form.Control.Feedback>
        </Form.Group>
      </div>

      <div style={{ marginTop: '1em' }}>
        <h5>Button group</h5>
        <ButtonGroup>
          <Button variant="primary">Primary</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="success">Success</Button>
          <Button variant="danger">Danger</Button>
          <Button variant="warning">Warning</Button>
          <Button variant="info">info</Button>
          <Button variant="light">Light</Button>
          <Button variant="dark">Dark</Button>
        </ButtonGroup>
      </div>

      <div style={{ marginTop: '2em' }}>
        <h5>Solid buttons</h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button variant="primary">Primary</Button>
          <Button variant="secondary">Secondary</Button>
          <Button variant="success">Success</Button>
          <Button variant="danger">Danger</Button>
          <Button variant="warning">Warning</Button>
          <Button variant="info">info</Button>
          <Button variant="light">Light</Button>
          <Button variant="dark">Dark</Button>
        </div>
      </div>


      <div style={{ marginTop: '1em' }}>
        <h5>Outlined buttons</h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button variant="outline-primary">Primary</Button>
          <Button variant="outline-secondary">Secondary</Button>
          <Button variant="outline-success">Success</Button>
          <Button variant="outline-danger">Danger</Button>
          <Button variant="outline-warning">Warning</Button>
          <Button variant="outline-info">info</Button>
          <Button variant="outline-light">Light</Button>
          <Button variant="outline-dark">Dark</Button>
        </div>
      </div>

      <div style={{ marginTop: '1em' }}>
        <h5>Alerts</h5>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          {[
            'primary',
            'secondary',
            'success',
            'danger',
            'warning',
            'info',
            'light',
            'dark',
          ].map((variant, idx) => (
            <Alert key={idx} variant={variant}>
              This is a {variant} alert—check it out!
            </Alert>
          ))}
        </div>
      </div>
      <div style={{ marginTop: '1em' }}>
        <h5>Badge</h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Badge bg="primary">Primary</Badge> <Badge bg="secondary">Secondary</Badge>{' '}
          <Badge bg="success">Success</Badge> <Badge bg="danger">Danger</Badge>{' '}
          <Badge bg="warning" text="dark">
            Warning
          </Badge>{' '}
          <Badge bg="info">Info</Badge>{' '}
          <Badge bg="light" text="dark">
            Light
          </Badge>{' '}
          <Badge bg="dark">Dark</Badge>
        </div>
      </div>
      <div style={{ marginTop: '1em' }}>
        <h5>Badge Pill</h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Badge pill bg="primary">
            Primary
          </Badge>{' '}
          <Badge pill bg="secondary">
            Secondary
          </Badge>{' '}
          <Badge pill bg="success">
            Success
          </Badge>{' '}
          <Badge pill bg="danger">
            Danger
          </Badge>{' '}
          <Badge pill bg="warning" text="dark">
            Warning
          </Badge>{' '}
          <Badge pill bg="info">
            Info
          </Badge>{' '}
          <Badge pill bg="light" text="dark">
            Light
          </Badge>{' '}
          <Badge pill bg="dark">
            Dark
          </Badge>
        </div>
      </div>
      <div style={{ marginTop: '1em' }}>
        <h5>Nav</h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Nav
            activeKey="/iceberg-styles-bootstrap-testing"
            onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
          >
            <Nav.Item>
              <Nav.Link href="/iceberg-styles-bootstrap-testing">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="disabled" disabled>
                Disabled
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div style={{ marginTop: '1em' }}>
        <h5>Navbar </h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Navbar className={Style.navbar}>
            <Container>
              <Navbar.Brand href="#home">Navbar</Navbar.Brand>
              <Nav className="me-auto">
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </div>
      <div style={{ marginTop: '1em' }}>
        <h5>Nav</h5>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Nav
            activeKey="/iceberg-styles-bootstrap-testing"
            onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
          >
            <Nav.Item>
              <Nav.Link href="/iceberg-styles-bootstrap-testing">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="disabled" disabled>
                Disabled
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </div>
  )
}

export default IcebergStylesTest;