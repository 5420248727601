import { Outlet } from "react-router";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import EnsureAuthToken from "./utils/auth/EnsureAuthToken";
import MainMenu from "./containers/MainMenu";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import SidebarWrapper from "./containers/Sidebar/SidebarWrapper";
import { Row } from "react-bootstrap";

const Layout = () => {
    LogRocket.init('msbcwv/startportalen');
    setupLogRocketReact(LogRocket);
    
    // redirect to login if not authenticated
    useMsalAuthentication(InteractionType.Redirect);
 
    return (
        <div>
            <AuthenticatedTemplate>
                <EnsureAuthToken>
                    <>
                        <MainMenu />
                        <div className="container-fluid">
                            <Row className="flex-nowrap">
                                <SidebarWrapper />
                                <main className="col">
                                    <Outlet />
                                </main>
                            </Row>
                        </div>
                    </>
                </EnsureAuthToken>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>Redirecting to login...</p>
            </UnauthenticatedTemplate>
        </div>
    );
}

export default Layout;