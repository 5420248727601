import LogRocket from 'logrocket';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import '@tradesolution/iceberg-styles/dist/css/iceberg.min.css';
import './index.scss';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/auth/authConfig";
import Layout from './Layout';
import Home from './containers/Home';
import Report from './containers/Report';
import IcebergStylesTest from './containers/IcebergStylesTest';
import NotFound from './containers/NotFound';

const msalInstance = new PublicClientApplication(msalConfig);
const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/report/:id' element={<Report />} />
          <Route path='/iceberg-styles-bootstrap-testing' element={<IcebergStylesTest />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </MsalProvider>
);

// run logrocket in production only
if (window.location.hostname === 'start.tradesolution.no') {
  LogRocket.init('msbcwv/startportalen');
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}