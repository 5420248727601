import axios from 'axios';

export interface IReport {
  accessToken: string;
  report: {
    datasetId: string;
    embedUrl: string;
    id: string;
    name: string;
    webUrl: string;
  };
}

export default class PowerBiReportService {
  getResource() {
    let resource = 'https://citadelapi.tradesolution.no/api';
    if (
      window.location.host === 'localhost' ||
      window.location.host === 'start-dev.tradesolution.no'
    ) {
      resource = 'https://citadelapi-dev.tradesolution.no/api';
    }
    return resource;
  }

  getById = (reportId: number, tsKundeId: string) => {
    const url = `${this.getResource()}/EmbededReports/${reportId}?tsKundeId=${tsKundeId}`;
    const request = axios.get(url).then((response: any) => response.data);
    return request;
  };
}
