import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IMenuData, IProductMenuItem } from '../../services/KundeportalenApi/types.interface';
import rapportFotostudioIcon from '../../images/rapport.fotostudio.svg';
import rapportFyllingsgradIcon from '../../images/rapport.fyllingsgradrapport.svg';
import rapportLanseringerIcon from '../../images/rapport.lanseringer.svg';
import rapportOtherIcon from '../../images/rapport.other.svg';
import Style from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { KundeportalenApi } from '../../services/KundeportalenApi';
import { useMsal } from '@azure/msal-react';
import { FileDto, Permission } from '../../services/KundeportalenApi/types';
import { useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';

const Home = () => {
  const [services, setServices] = useState<IProductMenuItem[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [visibleReports, setVisibleReports] = useState<any[]>([]);
  const [files, setFiles] = useState<FileDto[]>([]);

  const { selectedTsKunde } = useSelectedTsKunde();

  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const navigate = useNavigate();

  const hasAccessTo = (accessTo?: string): boolean => {
    console.log(selectedTsKunde);
    if (permissions.length === 0)
      return false;
    var tradesolutionAnsatt = permissions.some(x => x.role === "UberAdmin" || x.role === "TradesolutionAnsatt");
    if (tradesolutionAnsatt) {
      return true;
    }
    var relevantPermissions = permissions.filter(x => x.tsKundeId?.toLowerCase() === selectedTsKunde?.tsKundeId?.toLowerCase());
    if (relevantPermissions.some(x => x.accessTo === accessTo)) {
      return true;
    }
    return false;
  }

  const calculateReports = () => {
    const reports = []
    if (hasAccessTo()) {
      reports.push({
        displayName: 'Foto- og sjekkpunkt',
        url: '/report/c00ee448-2bf1-4683-9a13-b6086aec9225',
        iconSrc: rapportFotostudioIcon,
      });
    }
    if (hasAccessTo('InTrack.HorecaVaregruppeRapport')) {
      reports.push({
        displayName: 'HORECA Varegruppe',
        url: '/report/e66bd81a-cfbb-4f86-a7dc-85ca71435643',
        iconSrc: rapportOtherIcon,
      });
    }
    if (hasAccessTo('Intrack.HorecaMarkedsrapportRapportMnd')) {
      reports.push({
        displayName: 'HORECA Varegruppe måned',
        url: '/report/ddb8bf40-4451-48c9-b5bb-05e5659aba3e',
        iconSrc: rapportOtherIcon,
      });
    }
    if (hasAccessTo('InTrack.HorecaStrukturRapport')) {
      reports.push({
        displayName: 'HORECA Strukturrapport',
        url: '/report/3079c12a-7cee-4892-95c5-159779cd0f89',
        iconSrc: rapportOtherIcon,
      });
    }
    if (hasAccessTo('InTrack.KbsVaregruppeRapport')) {
      reports.push({
        displayName: 'KBS Varegruppe',
        url: '/report/f739c117-9864-4112-ae09-5c713c3f5c23',
        iconSrc: rapportOtherIcon,
      });
    }
    if (hasAccessTo('Intrack.KbsMarkedsrapportRapportMnd')) {
      reports.push({
        displayName: 'KBS Varegruppe måned',
        url: '/report/dc189d15-6306-4233-bc95-b0c0d3c25791',
        iconSrc: rapportOtherIcon,
      });
    }
    if (hasAccessTo('InTrack.KbsStrukturRapport')) {
      reports.push({
        displayName: 'KBS Strukturrapport',
        url: '/report/9fce2e97-b84d-4223-904d-7bdf2a96b49a',
        iconSrc: rapportOtherIcon,
      });
    }
    if (hasAccessTo('InTrack.GrossistVaregruppeRapport')) {
      reports.push({
        displayName: 'Grossist Varegrupperapport',
        url: '/report/3127bd5f-cd99-4887-8b60-cf062da69211',
        iconSrc: rapportOtherIcon,
      });
    }        
    if (hasAccessTo('EPD')) {
      reports.push({
        displayName: 'Lanseringsvindu',
        url: '/report/ced98ade-29f4-442c-b23d-385c85181dc4',
        iconSrc: rapportLanseringerIcon,
      });
      reports.push({
        displayName: 'Fyllingsgradrapport',
        url: '/report/e0c5a993-5d06-47b4-b75a-f609d30c92ae',
        iconSrc: rapportFyllingsgradIcon,
      });    
    }    
    setVisibleReports(reports);
  }; 

  const navigateToReport = (url: string) => navigate(url);

  useEffect(() => {
    calculateReports();
  }, [selectedTsKunde, permissions]);

  useEffect(() => {
    const loadFiles = async () => {
      const kundeportalApi = new KundeportalenApi();
      var result = await kundeportalApi.fetchFiles(selectedTsKunde.tsKundeId);
      setFiles(result);
    }
    if (selectedTsKunde) {
      loadFiles();
    }
  }, [selectedTsKunde]);

  useEffect(() => {
    const loadMenuTiles = async () => {
      const kundeportalApi = new KundeportalenApi();
      const menuData: IMenuData = await kundeportalApi.fetchMenu(false);
      setServices(menuData.tjenester);
    }
    loadMenuTiles();
  }, [name]);

  useEffect(() => {
    let isMounted = true;
    const loadPermissions = async () => {
      const kundeportalApi = new KundeportalenApi();
      var result = await kundeportalApi.fetchPermissions()
      setPermissions(result);
    }
    if (isMounted && name) {
      loadPermissions();
    }
    return () => { isMounted = false };
  }, [name]);

  return (
    <div className={Style.container}>
      <Row>
        <Col>
          <h1 className={Style.mainHeader}>Mine tjenester</h1>
        </Col>
      </Row>

      <Row className={Style.tileRow}>
        {services?.length > 0 && services.filter(x => x.displayName.toLowerCase().indexOf('start')).map((service) => (
          <Col
            key={service.link}
            className={Style.tileCol}
            xs={6} sm={4} md={3} lg={2}
          >
            <a href={service.link} className={Style.tileContent} >
              <img
                src={service.v2IconUrl}
                alt={service.displayName}
                className={Style.productIcon}
              />
              <p className={Style.productName}>{service.displayName}</p>
            </a>
          </Col>
        ))}        
      </Row>

      <Row>
        <Col>
          <h2 className={Style.mainHeader}>
            Mine rapporter
          </h2>
        </Col>
      </Row>
      <Row className={Style.tileRow}>
        {visibleReports?.length > 0 && visibleReports.map((report) => (
          <Col
            key={report.url}
            className={Style.tileCol}
            xs={6} sm={4} md={3} lg={2}
          >
            <div className={Style.tileContent} onClick={() => navigateToReport(report.url)}>
              <img
                src={report.iconSrc}
                alt={report.displayName}
                className={Style.productIcon}
              />
              <p className={Style.productName}>{report.displayName}</p>
            </div>
          </Col>          
        ))}
        {files?.length > 0 && files.map((file) => (
          <Col
            key={file.url}
            className={Style.tileCol}
            xs={6} sm={4} md={3} lg={2}
          >
            <a href={file.url} className={Style.tileContent} download={file.name}>
              <img
                src={file.iconUrl ?? rapportOtherIcon}
                alt={file.name}
                className={Style.productIcon}
              />
              <p className={Style.productName}>{file.name}</p>
            </a>
          </Col>
        ))}        
      </Row>

    </div >
  );
}

export default Home;