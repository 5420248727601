import axios from 'axios';
import { FileDto, Permission } from './types';
export class KundeportalenApi {
  baseUrl = 'https://kundeportalenapi.tradesolution.no/api';

  constructor() {
    if (
      window.location.host.indexOf('test') > -1 ||
      window.location.host.indexOf('dev') > -1
    ) {
      this.baseUrl = 'https://kundeportalenapi-dev.azurewebsites.net/api';
    }

    if (window.location.host.indexOf('localhost') > -1) {
      this.baseUrl = 'https://localhost:56066/api';
      //this.baseUrl = 'https://kundeportalenapi-dev.azurewebsites.net/api';
    }
  } 

  fetchMenu = (isTileDisplay: any) =>
    axios
      .get(`${this.baseUrl}/menu/me?isTileDisplay=${isTileDisplay}`)
      .then((response) => response.data);

  fetchPermissions = () : Promise<Permission[]> => {
    return axios
    .get(`${this.baseUrl}/permissions/me`)
    .then((response) => response.data);
  }

  fetchFiles = (tsKundeId: any) : Promise<FileDto[]> => 
    axios
    .get(`${this.baseUrl}/files/me?tsKundeId=${tsKundeId}`)
    .then((response) => response.data);
}
