import Style from './index.module.css';

const NotFound = () => (
  <div className={Style.container}>
    <h1>
      Wuooops, her skal du ikke være! 😳
    </h1>
    <h3>Heldigvis er det bare å trykke på Tradesolution-logoen oppe til venstre og vi vil ta deg tilbake til vår landingsside 😊</h3>
    <img src="https://i.imgflip.com/22d5z4.jpg" title="made at imgflip.com" alt="" />
  </div>
);

export default NotFound;