import { useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import PowerBiReportService, { IReport } from '../../services/PowerBiReportService';
import Style from './index.module.css';
import { useEffect, useState } from 'react';
import { useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
interface Props {
}

declare global {
  interface Window {
    report: any;
  }
}

const Report = (props: Props) => {

  const { selectedTsKunde } = useSelectedTsKunde();

  const [pbiReport, setPbiReport] = useState<IReport>();
  const selectedTsKundeId: string | undefined = selectedTsKunde?.tsKundeId;

  const { id }: any = useParams();
  const utcHour = new Date().getUTCHours();

  const [embedConfig, setEmbedConfig] = useState<any>(null);

  useEffect(() => {
    setEmbedConfig({
      type: 'report',
      id: pbiReport?.report.id,
      embedUrl: pbiReport?.report.embedUrl,
      accessToken: pbiReport?.accessToken,
      tokenType: models.TokenType.Embed,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: models.BackgroundType.Default,
      }
    });
  }, [pbiReport]);

  const eventHandlers = new Map([
    ['loaded', function () { console.log('Report loaded'); }],
    ['rendered', function () { console.log('Report rendered'); }],
    ['error', function (event: any) { console.log(event.detail); }]
  ]);

  useEffect(() => {
    console.log('selectedTsKundeId: ' + selectedTsKundeId)
    console.log('id: ' + id)
    const getReport = async (tsKundeId: string) => {
      const powerBiReportService = new PowerBiReportService();
      const res: IReport = await powerBiReportService.getById(id, tsKundeId);
      if (res) {
        console.log('reportdata loaded')
        setPbiReport(res);
      }
    }

    if (selectedTsKundeId && id) {
      getReport(selectedTsKundeId.toUpperCase());
    }
  }, [id, selectedTsKundeId]);

  return (
    <div className={Style.container}>
      {(utcHour < 4) || (utcHour >= 21) ? (
        <div className={Style.unavailableMsgContainer}>
          <h4 className={Style.unavailableMsg}>
            Rapporter er tilgjengelige mellom kl. 06:00 og kl. 22:00. Ta
            kontakt med <a href="mailto: support@tradesolution.no">support@tradesolution.no</a> hvis det er ønskelig å utvide
            tidsrommet.
          </h4>
        </div>
      ) : (
        <div className={Style.powerBiReportContainer}>
          {embedConfig && pbiReport && (
            <PowerBIEmbed
              embedConfig={embedConfig}
              eventHandlers={eventHandlers}
              cssClassName={Style.powerBiEmbed}
              getEmbeddedComponent={(embeddedReport) => {
                return embeddedReport;
              }}
            />
          )}
        </div >
      )}
    </div>
  );
}

export default Report;